import Domain from '@/types/Domain';
import Money from '@/domains/common/Money';
import ArticleImage from './ArticleImage';
import ArticleVariant from './ArticleVariant';
import fillArray from '@/utils/fillArray';
import sortByProp from '@/utils/sortByProp';
import flattenProps from '@/utils/flattenProps';
import { notWhitespacesOnly } from '@/utils/validations';
import formDataCleanEmptyKeys from '@/utils/formDataCleanEmptyKeys';

interface Props {
  active: boolean;
  bulletPoints: string[];
  categoryId: string;
  tagIds: string[];
  categoryTagId: string;
  description: string;
  id: string;
  images: ArticleImage[];
  mandantId: string;
  manufacturerId: string;
  name: string;
  articlePrice: Money;
  storageLocation: string;
  taxRate: number;
  unitFee: Money;
  variants: ArticleVariant[];
  recommendedRetailPrice?: Money;
  articleInfoDocument?: File;
  documentUrl?: string;
  categoryShortcut?: string;
}

class Article extends Domain implements Props {
  public static MAX_BULLET_POINTS_AMOUNT: number = 5;

  public static createAll(articles: any[]): Article[] {
    return articles.map(Article.create);
  }

  public static create(data: any): Article {
    return new Article(
      data.active,
      fillArray(data.bulletPoints, Article.MAX_BULLET_POINTS_AMOUNT, ''),
      data.categoryId ||
        data.categorySpecificPortfolioId ||
        // TODO: remove this fallback after correct implementation in API
        (data.categorySpecificPortfolio && data.categorySpecificPortfolio.id) ||
        '',
      data.tagIds,
      data.categoryTagId,
      data.description,
      data.id,
      ArticleImage.createAll(sortByProp(data.images || [], 'order')),
      data.mandantId,
      data.manufacturerId || '',
      data.name,
      Money.create(data.articlePrice || {}),
      //data.storageLocation, this will have an error while article editing
      data.storageLocation !== undefined ? data.storageLocation : '',
      data.taxRate !== undefined ? data.taxRate : '',
      Money.create(data.unitFee || {}),
      ArticleVariant.createAll(data.variants || []),
      Money.create({ amount: data.recommendedRetailPrice } || {}),
      data.articleInfoDocument || data.documentUrl,
      (data.categorySpecificPortfolio &&
        data.categorySpecificPortfolio.shortcut) ||
        ''
    );
  }

  public static createDefault(): Article {
    return Article.create({
      active: false,
      description: '',
      name: '',
    });
  }

  private constructor(
    public active: boolean,
    public bulletPoints: string[],
    public categoryId: string,
    public tagIds: string[],
    public categoryTagId: string,
    public description: string,
    public id: string,
    public images: ArticleImage[],
    public mandantId: string,
    public manufacturerId: string,
    public name: string,
    public articlePrice: Money,
    public storageLocation: string,
    public taxRate: number,
    public unitFee: Money,
    public variants: ArticleVariant[],
    public recommendedRetailPrice?: Money,
    public articleInfoDocument?: File,
    public categoryShortcut?: string
  ) {
    super();
  }

  public constraints(): {} {
    const bulletPointConstraints = {};
    for (let i = 0; i < Article.MAX_BULLET_POINTS_AMOUNT; i++) {
      bulletPointConstraints[`bulletPoints.${i}`] = {
        format: {
          pattern: /^$|^.{3,80}$/,
          message: '^Ein Bullet Point muss zwischen 3 und 80 Zeichen lang sein',
        },
      };
    }

    return {
      ...bulletPointConstraints,
      'articleInfoDocument.name': {
        format: {
          pattern: /^.*\.(pdf|PDF)$/,
          message: '^Die Datei ist nicht vom hier erlaubten Typ, pdf.',
        },
      },
      'articleInfoDocument.size': {
        numericality: {
          greaterThan: 0,
          lessThanOrEqualTo: 10489000,
          message: '^Die Datei ist zu groß. Erlaubt sind maximal 10MB.',
        },
      },
      categoryId: {
        presence: {
          message: '^Kategorie ist ein Pflichtfeld',
        },
      },
      description: {
        presence: {
          message: '^Beschreibung ist ein Pflichtfeld',
        },
        format: {
          pattern: /^(.|\r\n|\r|\n){3,10000}$/,
          message: '^Beschreibung muss zwischen 3 und 10.000 Zeichen lang sein',
        },
      },
      images: {
        length: {
          maximum: 3,
          message: '^Es dürfen maximal 3 Bilder hochgeladen werden',
        },
      },
      mandantId: {
        presence: false,
        format: {
          pattern: notWhitespacesOnly,
          message: '^Mandant ist ein Pflichtfeld',
        },
      },
      manufacturerId: {
        presence: {
          message: '^Hersteller ist ein Pflichtfeld',
        },
      },
      name: {
        presence: {
          message: '^Artikelbezeichnung ist ein Pflichtfeld',
        },
        format: {
          pattern: /^.{2,100}$/,
          message:
            '^Artikelbezeichnung muss zwischen 2 und 100 Zeichen lang sein',
        },
      },
      articlePrice: {
        presence: {
          message: '^VK B2B ist ein Pflichtfeld',
        },
      },
      'articlePrice.amount': {
        numericality: {
          onlyInteger: true,
          greaterThan: 0,
          notGreaterThan: '^VK B2B muss größer als 0 sein',
        },
      },
      storageLocation: {
        presence: {
          message: '^Lagerort ist ein Pflichtfeld',
        },
      },
      taxRate: {
        presence: {
          message: '^Steuersatz ist ein Pflichtfeld',
        },
      },
      unitFee: {
        presence: {
          message: '^Handlingspauschale ist ein Pflichtfeld',
        },
      },
      'unitFee.amount': {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo:
            '^Handlingspauschale darf nicht negativ sein',
        },
      },
      recommendedRetailPrice: {
        presence: false,
      },
      'recommendedRetailPrice.amount': {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: '^UVP darf nicht negativ sein',
        },
      },
      variants: {
        presence: {
          message: '^Es muss mindestens 1 Variante angelegt werden',
        },
      },
    };
  }

  public clone(): Article {
    const clone = JSON.parse(JSON.stringify(this));
    return Article.create({
      ...clone,
      images: this.images.map((image) => image.clone()),
      variants: this.variants.map((variant) => variant.clone()),
    });
  }

  // TODO: remove this as soon as no more hacks are needed for this specific domain
  public toFormData(
    additionalExcludes: string[] = ['recommendedRetailPrice']
  ): FormData {
    const { articleInfoDocument, recommendedRetailPrice } = this;

    this.cleanse();

    const data = new FormData();

    const thisObj: any = this.clone();

    if (articleInfoDocument) {
      data.append('articleInfoDocument', articleInfoDocument as Blob);
    }

    if (recommendedRetailPrice.amount > 0) {
      data.append(
        'recommendedRetailPrice',
        recommendedRetailPrice.amount.toString()
      );
    }

    // TODO: try to remove these hacks by changing some endpoint structure in the API
    if (thisObj.bulletPoints) {
      const newBulletPoints: string[] = [];
      thisObj.bulletPoints.forEach((bulletPoint: string) => {
        if (bulletPoint) {
          newBulletPoints.push(bulletPoint);
        }
      });
      thisObj.bulletPoints = newBulletPoints;
    }

    thisObj.categorySpecificPortfolioId = thisObj.categoryId;
    delete thisObj.categoryId;
    // TODO: add image modifier here
    if (thisObj.images) {
      let order = 1;
      thisObj.images = thisObj.images.map((image: ArticleImage) => {
        image.order = order++;
        if (image.isNew) {
          delete image.url;
          delete image.filename;
          delete image.size;
          delete image.extension;
        }
        delete image.height;
        delete image.width;
        delete image.hasAlpha;
        delete image.isNew;

        return image;
      });
    }
    if (thisObj.variants) {
      thisObj.variants.forEach((variant: ArticleVariant) => {
        if (variant.images) {
          let order = 1;
          variant.images = variant.images.map((image: ArticleImage) => {
            image.order = order++;
            if (image.isNew) {
              delete image.url;
              delete image.filename;
              delete image.size;
              delete image.extension;
            }
            delete image.height;
            delete image.width;
            delete image.hasAlpha;
            delete image.isNew;

            return image;
          });
        }
      });
    }

    const excludedProperties = [...additionalExcludes];

    excludedProperties.forEach((key) => {
      delete thisObj[key];
    });

    const flattenedProps = flattenProps(thisObj);

    Object.keys(flattenedProps).forEach((key) => {
      const prop = flattenedProps[key];
      data.append(key, prop);
    });

    return formDataCleanEmptyKeys(data);
  }

  public getArticleImages(variantId: string): ArticleImage[] {
    const variant = this.variants.find((item) => {
      return item.id === variantId;
    });

    if (variantId && variant && variant.images.length > 0) {
      return variant.images;
    } else {
      return this.images;
    }
  }

  public getBulletPoints(variantId: string): string[] {
    const variant = this.variants.find((item) => {
      return item.id === variantId;
    });

    if (
      variantId &&
      variant &&
      variant.bulletPoints.find((item) => item !== '')
    ) {
      return variant.bulletPoints;
    } else {
      return this.bulletPoints;
    }
  }

  public getDescription(variantId: string): string {
    const variant = this.variants.find((item) => {
      return item.id === variantId;
    });

    if (variantId && variant && variant.description) {
      return variant.description;
    } else {
      return this.description;
    }
  }
}

export default Article;

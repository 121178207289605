import { MutationTree } from 'vuex';
import { ArticleState } from './state';
import { ListInfo } from '@/types/ListInfo';
import Article from '@/domains/article/Article';
import MinimalArticle from '@/domains/article/MinimalArticle';
import NormalizedData from '@/types/NormalizedData';

export const addArticles = (
  state: ArticleState,
  articles: NormalizedData<Article>
): void => {
  state.articles = {
    ...state.articles,
    ...articles,
  };
};

export const setMinimalArticles = (
  state: ArticleState,
  minimalArticles: NormalizedData<MinimalArticle>
): void => {
  state.minimalArticles = {
    ...minimalArticles,
  };
};

export const setArticleListInfo = (
  state: ArticleState,
  info: ListInfo
): void => {
  state.listInfo = info;
};

export const loadArticle = (state: ArticleState, clone: Article) => {
  // Clone and sanitize the article
  const temp = clone.clone();
  temp.id = undefined;
  temp.name = temp.name + ' (Kopie)';
  temp.storageLocation = '';
  temp.images = [];
  temp.variants = temp.variants.map((variant) => {
    variant.id = undefined;
    variant.ekEbootisId = undefined;
    variant.mkEbootisId = undefined;
    variant.images = [];
    return variant;
  });

  // Set the clone to the state
  state.cloneArticle = temp;
};

export const clearArticleClone = (state: ArticleState) => {
  state.cloneArticle = undefined;
};

export const mutations: MutationTree<ArticleState> = {
  addArticles,
  setMinimalArticles,
  setArticleListInfo,
  loadArticle,
  clearArticleClone,
};

export default mutations;
